'use client';

import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { redstone } from '../chains/redstone';
import { garnetHolesky, redstoneHolesky } from '../chains/chains';
import { NavChainSelectItem } from './NavChainSelectItem';
import { activeChainId } from '../common';
import { cn } from '@/utils/cn';

export function NavChainSelect() {
  return (
    <div className="flex h-full items-center gap-4 md:gap-6">
      <Menu as="div" className="relative inline-block h-full text-left">
        <Menu.Button className="h-full">
          {({ open }) => {
            return <NavChainSelectItem chainId={activeChainId} active open={open} />;
          }}
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={cn(
              'absolute left-0 z-10 w-full origin-top-right border border-white/20 bg-[#141414] focus:outline-none lg:w-[225px]',
              {
                'lg:w-[330px]': activeChainId === redstoneHolesky.id,
              },
            )}
          >
            <div className="flex h-[32px] items-center justify-between px-4 py-5">
              <h4 className="text-[12px] uppercase text-white/30">Switch chain</h4>
              <p className="text-primary text-[12px] uppercase">About</p>
            </div>
            <div>
              {activeChainId !== redstone.id && (
                <Menu.Item>
                  <NavChainSelectItem chainId={redstone.id} />
                </Menu.Item>
              )}

              {activeChainId !== garnetHolesky.id && (
                <Menu.Item>
                  <NavChainSelectItem chainId={garnetHolesky.id} />
                </Menu.Item>
              )}

              {/* {activeChainId !== rhodolite.id && (
                <Menu.Item>
                  <NavChainSelectItem chainId={rhodolite.id} />
                </Menu.Item>
              )} */}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
