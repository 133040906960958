import { useEffect, useState } from 'react';
import { getDefaultConfig } from '@rainbow-me/rainbowkit';
import { RainbowKitChain } from '@rainbow-me/rainbowkit/dist/components/RainbowKitProvider/RainbowKitChainContext';
import { Chain } from 'viem';
import { Config } from 'wagmi';
import { configureDynamicChains } from '@reservoir0x/relay-sdk';
import chainList from '@/chains';
import { activeChainId } from '@/common';
import { garnetHolesky } from '@/chains/garnetHolesky';

const ACTIVE_CHAIN_IDS = [
  parseInt(process.env.NEXT_PUBLIC_L1_CHAIN_ID as string),
  parseInt(process.env.NEXT_PUBLIC_L2_CHAIN_ID as string),
];

export const useConnectWallet = () => {
  const [wagmiConfig, setWagmiConfig] = useState<Config | null>(null);
  const [chains, setChains] = useState<RainbowKitChain[]>([]);

  useEffect(() => {
    configureDynamicChains()
      .then((newChains) => {
        let chains;
        const customChains = chainList.filter((chain) => ACTIVE_CHAIN_IDS.includes(chain.id));

        if (activeChainId === garnetHolesky.id) {
          chains = customChains;
        } else {
          chains = [
            ...customChains,
            ...newChains.map(({ viemChain }) => viemChain as Chain),
          ].reduce((accumulator, currentChain) => {
            if (!accumulator.find((chain) => chain.id === currentChain.id)) {
              accumulator.push(currentChain);
            }
            return accumulator;
          }, []);
        }

        const newWagmiConfig = getDefaultConfig({
          appName: 'Bridge',
          projectId: process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID as string,
          chains: chains,
        });

        setWagmiConfig(newWagmiConfig);
        setChains(chains);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return { chains, wagmiConfig };
};
