import { createContext, memo, ReactNode, useContext, useMemo } from 'react';
import { RainbowKitChain } from '@rainbow-me/rainbowkit/dist/components/RainbowKitProvider/RainbowKitChainContext';

type WalletContextType = {
  chains: RainbowKitChain[];
};

type WalletProviderProps = {
  chains: RainbowKitChain[];
  children: ReactNode;
};

export const WalletContext = createContext<WalletContextType>({
  chains: [],
});

export const WalletProvider = memo<WalletProviderProps>(function WalletProvider({
  chains,
  children,
}: WalletProviderProps) {
  return <WalletContext.Provider value={{ chains }}>{children}</WalletContext.Provider>;
});

export function useWalletContext() {
  return useContext(WalletContext);
}
