import { Network } from '@/types/Network';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export const l1ChainID = parseInt(publicRuntimeConfig.l1ChainID);

export const CHAIN_ID_TO_NETWORK: Record<number, Network> = {
  1: 'homestead',
  5: 'goerli',
  11155111: 'sepolia',
  888: 'quarry-devnet',
  690: 'redstone',
  17000: 'holesky',
  17001: 'redstone-holesky',
  17069: 'garnet-holesky',
  8453: 'base',
  84531: 'base-goerli',
  84532: 'base-sepolia',
  894: 'redstone-894',
};

export function getL1NetworkForChainEnv() {
  return CHAIN_ID_TO_NETWORK[l1ChainID];
}
