'use client';

import { ReactNode } from 'react';
import { cn } from '@/utils/cn';
import { activeChainId } from '@/common';
import { redstone } from '../chains/redstone';
import { garnetHolesky, redstoneHolesky, rhodolite } from '../chains/chains';
import { NavChainSelect } from './NavChainSelect';
import { NavSidebar, MobileNavSidebar } from '@/ui/NavSidebar';
import { SocialConnect } from '@/ui/SocialConnect';

type Props = {
  children: ReactNode;
};

export function PageContainer({ children }: Props) {
  return (
    <div className="grid max-w-full md:grid-cols-[min-content_auto] md:grid-rows-[min-content_auto]">
      <div
        className={cn(
          'font-mono text-sm leading-none text-white',
          'col-span-2 flex flex-col items-stretch justify-between gap-3',
          'md:flex-row md:items-center md:gap-8',
          {
            'bg-redstone': activeChainId === redstone.id,
            'bg-rhodolite': activeChainId === rhodolite.id || activeChainId === redstoneHolesky.id,
            'bg-garnet-holesky': activeChainId === garnetHolesky.id,
          },
        )}
      >
        <div className="flex h-full items-center justify-between">
          <NavChainSelect />
          <div className="md:hidden">
            <MobileNavSidebar />
          </div>
        </div>

        <SocialConnect />
      </div>
      <div className="hidden md:block">
        <NavSidebar />
      </div>
      <div className="col-span-2 min-w-0 md:col-span-1">{children}</div>
    </div>
  );
}
