import './global.css';
import '@rainbow-me/rainbowkit/styles.css';
import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { Header } from '@/components/Header/Header';
import { Layout } from '@/components/Layout/Layout';
import { OFACProvider } from '@/contexts/OFACContext';
import { TOSProvider } from '@/contexts/TOSContext';
import { WalletProvider } from '@/contexts/WalletContext';
import { useConnectWallet } from '@/wallet/useConnectWallet';
import App, { AppContext, AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { WagmiProvider } from 'wagmi';
import { PageContainer } from '@/ui/PageContainer';
import { fontBasierCircle, fontPPSupplyMono } from '@/fonts';
import { redstone } from '@/chains/redstone';
import { garnetHolesky } from '@/chains/garnetHolesky';
import { rhodolite } from '@/chains/rhodolite';
import { redstoneHolesky } from '@/chains/redstoneHolesky';
import { holesky, mainnet } from 'viem/chains';
import {
  createClient,
  convertViemChainToRelayChain,
  TESTNET_RELAY_API,
  MAINNET_RELAY_API,
} from '@reservoir0x/relay-sdk';
import { l1ChainID } from '@/utils/networks/getL1NetworkForChainEnv';

export type ChainId = keyof typeof primaryColor;

export const activeChainId: ChainId = process.env.NEXT_PUBLIC_L2_CHAIN_ID
  ? (Number(process.env.NEXT_PUBLIC_L2_CHAIN_ID) as ChainId)
  : redstone.id;

const primaryColor = {
  [redstone.id]: 'var(--color-redstone)',
  [garnetHolesky.id]: 'var(--color-garnet-holesky)',
  [rhodolite.id]: 'var(--color-rhodolite)',
  [redstoneHolesky.id]: 'var(--color-rhodolite)',
} as const;

const primaryColorHover = {
  [redstone.id]: 'var(--color-redstone-hover)',
  [garnetHolesky.id]: 'var(--color-garnet-holesky-hover)',
  [rhodolite.id]: 'var(--color-rhodolite-hover)',
  [redstoneHolesky.id]: 'var(--color-rhodolite-hover)',
} as const;

// https://nextjs.org/docs/advanced-features/custom-app
export async function getInitialProps(context: AppContext) {
  const appProps = await App.getInitialProps(context);
  return appProps;
}

const queryClient = new QueryClient();
const allowedPaths = new Set([
  '/withdraw',
  '/deposit',
  '/transactions',
  '/bridge',
  '/faucet',
  '/claim-eth',
]);

createClient({
  baseApiUrl: l1ChainID === mainnet.id ? MAINNET_RELAY_API : TESTNET_RELAY_API,
  source: `${l1ChainID}-bridge`,
  chains: [convertViemChainToRelayChain(l1ChainID === mainnet.id ? mainnet : holesky)],
});

function Root({ Component, pageProps }: AppProps) {
  const { pathname } = useRouter();
  const { ...props } = pageProps as { props: unknown };
  const { chains, wagmiConfig } = useConnectWallet();

  if (!wagmiConfig) {
    return null;
  }

  return (
    <>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <style jsx global>
        {`
          :root {
            --font-supply-mono: ${fontPPSupplyMono.style.fontFamily};
            --font-basier-circle: ${fontBasierCircle.style.fontFamily};
            --color-redstone: #f34242;
            --color-garnet-holesky: #a91f2f;
            --color-rhodolite: #6d2436;
            --color-redstone-hover: #db3b3b;
            --color-garnet-holesky-hover: #981c2a;
            --color-rhodolite-hover: #622031;
            --primary-color: ${primaryColor[activeChainId]};
            --primary-hover-color: ${primaryColorHover[activeChainId]};
          }
        `}
      </style>
      <QueryClientProvider client={queryClient}>
        <WalletProvider chains={chains}>
          <WagmiProvider config={wagmiConfig}>
            <RainbowKitProvider modalSize="compact">
              <TOSProvider>
                <OFACProvider>
                  <PageContainer>
                    <Layout>
                      <div>
                        {(pathname === '/' || !allowedPaths.has(pathname)) && (
                          <Component {...props} />
                        )}
                        {allowedPaths.has(pathname) && (
                          <>
                            <Header />
                            <div className="m-0 w-full p-0">
                              <Component {...props} />
                            </div>
                          </>
                        )}
                      </div>
                    </Layout>
                  </PageContainer>
                </OFACProvider>
              </TOSProvider>
            </RainbowKitProvider>
          </WagmiProvider>
        </WalletProvider>
      </QueryClientProvider>
      <ToastContainer position="bottom-right" theme="dark" />
    </>
  );
}

Root.getInitialProps = getInitialProps;

export default Root;
