import { createContext, memo, ReactNode, useContext, useMemo } from 'react';
import { Hex } from 'viem';
import { useAccount } from 'wagmi';
import { useQuery } from '@tanstack/react-query';

type OFACContextType = {
  isOFACAllowed: boolean;
  isOFACAllowedLoading: boolean;
};

type OFACProviderProps = {
  children: ReactNode;
};

export const OFACContext = createContext<OFACContextType>({
  isOFACAllowed: false,
  isOFACAllowedLoading: false,
});

export async function fetchIsAllowed(address?: Hex): Promise<{ result: boolean }> {
  if (!address) {
    return { result: false };
  }

  const response = await fetch(process.env.NEXT_PUBLIC_COMPLIANCE_API_URL as string, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      jsonrpc: '2.0',
      method: 'verifier_isAllowed',
      params: [address],
      id: 0,
    }),
  });

  return (await response.json()) as { result: boolean };
}

export const OFACProvider = memo<OFACProviderProps>(function OFACProvider({
  children,
}: OFACProviderProps) {
  const { address } = useAccount();
  const { data: isOFACAllowed, isLoading: isOFACAllowedLoading } = useQuery({
    queryKey: ['isOFACBlocked', address],
    queryFn: async () => fetchIsAllowed(address),
    select: (r) => r.result,
  });

  const contextValue = useMemo(
    () => ({
      isOFACAllowed: !address || (address && Boolean(isOFACAllowed)),
      isOFACAllowedLoading,
    }),
    [isOFACAllowed, isOFACAllowedLoading, address],
  );

  return <OFACContext.Provider value={contextValue}>{children}</OFACContext.Provider>;
});

export function useOFACStatus() {
  return useContext(OFACContext);
}
