/* eslint-disable jsx-a11y/anchor-is-valid */
import { Popover } from '@headlessui/react';
import { useRouter } from 'next/router';

const PATH_TITLE: { [key: string]: string } = {
  '/transactions': 'Transactions',
  '/deposit': 'Bridge',
  '/withdraw': 'Bridge',
  '/bridge': 'Bridge',
  '/faucet': 'Faucet',
  '/claim-eth': 'Faucet',
};

export function Header() {
  const { pathname } = useRouter();

  return (
    <Popover className="relative">
      <header className="px-4 py-5 lg:px-8">
        <nav className="flex flex-row items-center sm:justify-between">
          <h2 className="grow	text-center font-mono text-2xl uppercase text-white sm:grow-0">
            {PATH_TITLE[pathname]}
          </h2>
        </nav>
      </header>
    </Popover>
  );
}
