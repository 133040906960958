import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Popover } from '@headlessui/react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { DisconnectWalletButton } from '@/components/DisconnectWalletButton/DisconnectWalletButton';
import { useIsPermittedToBridge } from '@/utils/hooks/useIsPermittedToBridge';
import Image from 'next/image';
import Link from 'next/link';
import { DotsIcon } from '../../ui/icons/DotsIcon';
import { usePathname, useRouter } from 'next/navigation';

type WalletModalProps = {
  isOpen: boolean;
  address: string;
  displayAddress: string;
  Close: typeof Popover.Button;
};

export function WalletModal({ isOpen, displayAddress, address, Close }: WalletModalProps) {
  return (
    <div
      className={`relative z-10 ${isOpen ? 'block' : 'hidden'} `}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <Close>
        <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
      </Close>
      <div className="fixed right-[32px] top-[40px] z-10 box-border w-[300px] overflow-y-auto bg-black sm:bg-transparent">
        <div className="flex h-full justify-center text-center sm:min-h-full sm:items-center sm:p-0">
          <div className="modal box-border flex w-[300px] w-full transform flex-col items-start justify-center overflow-hidden rounded-lg p-0 text-left shadow-xl transition-all sm:relative sm:my-8">
            <div className="absolute right-3 top-3 flex w-full flex-row items-center justify-end sm:relative sm:right-0 sm:top-0 sm:p-4 sm:px-6" />
            <div className="mb-4 mt-4 flex w-full flex-row justify-center">
              <Image src="/icons/default-avatar.svg" alt="avatar" width={64} height={64} />
            </div>
            <h5 className="flex w-full justify-center font-mono text-base text-white">
              {displayAddress}
              <CopyToClipboard text={address}>
                <Image
                  src="/icons/copy.svg"
                  width="16"
                  height="16"
                  alt="copy"
                  className="ml-3 cursor-pointer"
                />
              </CopyToClipboard>
            </h5>
            <div className="w-full items-center justify-center p-4 pb-8 pt-6 text-center">
              <div className="inline-block">
                <DisconnectWalletButton />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function RainbowConnectButton() {
  const { push } = useRouter();
  const pathname = usePathname();
  const [loggedIn, setLoggedIn] = useState(false);
  const isPermittedToBridge = useIsPermittedToBridge();

  return (
    <ConnectButton.Custom>
      {({ account, chain, authenticationStatus, mounted, openConnectModal }) => {
        const handleOpenConnectModal = () => {
          localStorage.setItem('autoconnect', '1');
          openConnectModal();
          setLoggedIn(true);
        };
        const ready = mounted && authenticationStatus !== 'loading';
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === 'authenticated');

        if (pathname === '/' && connected && loggedIn && isPermittedToBridge) {
          void push('/deposit');
        }
        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              style: {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <button
                    onClick={handleOpenConnectModal}
                    type="button"
                    className="text-primary h-[48px] bg-white px-2 py-3 font-mono text-[16px] uppercase md:w-[210px] md:px-4"
                  >
                    Connect<span className="hidden sm:inline"> wallet</span>
                  </button>
                );
              }

              if (pathname === '/') {
                return (
                  <div className="rounded bg-white px-4 py-3.5 text-center font-mono">
                    <Link href="/deposit">Bridge assets</Link>
                  </div>
                );
              }

              return (
                <div className="flex flex-col sm:flex-row sm:items-center sm:justify-center">
                  <Popover className="relative">
                    <Popover.Button className="text-md flex h-[48px] shrink-0 flex-row items-center justify-between gap-[10px] border border-neutral-700 bg-black px-4 py-3 font-mono font-medium">
                      <Image
                        src="/icons/default-avatar.svg"
                        className="mr-2 h-[28px] w-[28px] shrink-0 rounded-full bg-white"
                        width="22"
                        height="22"
                        alt="Avatar"
                      />
                      {account.displayName}
                      <DotsIcon className="h-[18px] w-[18px]" />
                    </Popover.Button>
                    <Popover.Panel>
                      <WalletModal
                        address={account.address}
                        displayAddress={account.displayName}
                        Close={Popover.Button}
                        isOpen
                      />
                    </Popover.Panel>
                    <div className="hidden pt-8">
                      <DisconnectWalletButton />
                    </div>
                  </Popover>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
}
