import { usePathname } from 'next/navigation';
import { Popover } from '@headlessui/react';
import { garnetHolesky, redstoneHolesky } from '../chains/chains';
import { activeChainId } from '@/common';
import { NavLink } from '@/ui/NavLink';
import { cn } from '@/utils/cn';
import { CloseIcon } from '@/ui/icons/CloseIcon';
import { HamburgerIcon } from 'public/icons/HamburgerIcon';
import Image from 'next/image';

export function NavSidebar() {
  const pathname = usePathname();
  return (
    <div className="divide-y divide-white/20 leading-none sm:w-52 md:sticky md:top-0 md:py-4 md:text-sm xl:w-72 xl:px-8">
      <div className="flex flex-col py-2">
        <NavLink href="/">Home</NavLink>
        <NavLink href="https://community.redstone.xyz/" target="_blank">
          Community
        </NavLink>
      </div>

      <div className="flex flex-col py-2">
        <NavLink href="/bridge" active={pathname === '/bridge'}>
          Bridge
        </NavLink>

        {activeChainId !== redstoneHolesky.id && (
          <NavLink href="/data-availability" active={pathname?.startsWith('/data-availability')}>
            Data Availability
          </NavLink>
        )}

        {activeChainId === garnetHolesky.id && (
          <NavLink
            href="/faucet"
            active={pathname?.startsWith('/faucet') || pathname?.startsWith('/claim-eth')}
          >
            Faucet
          </NavLink>
        )}

        {activeChainId !== redstoneHolesky.id && (
          <NavLink href={process.env.NEXT_PUBLIC_L2_EXPLORER_URL as string} target="_blank">
            Explorer
            <Image
              src="/icons/new-tab.svg"
              alt="New tab icon"
              width={30}
              height={30}
              className="inline-block h-5 pb-1 pl-1"
            />
          </NavLink>
        )}

        <NavLink href="https://status.lattice.xyz/" target="_blank">
          Status
          <Image
            src="/icons/new-tab.svg"
            alt="New tab icon"
            width={30}
            height={30}
            className="inline-block h-5 pb-1 pl-1"
          />
        </NavLink>
      </div>
      <div className="flex flex-col py-2">
        <NavLink href="/docs/what-is-redstone" active={pathname === '/docs/what-is-redstone'}>
          What is Redstone?
        </NavLink>
        {Number(process.env.NEXT_PUBLIC_L2_CHAIN_ID) === garnetHolesky.id && (
          <NavLink href="/docs/faucet" active={pathname === '/docs/faucet'}>
            Get test ETH
          </NavLink>
        )}
        <NavLink href="/docs/network-info" active={pathname === '/docs/network-info'}>
          Network info
        </NavLink>
        <NavLink href="/docs/contract-addresses" active={pathname === '/docs/contract-addresses'}>
          Contract addresses
        </NavLink>
        <NavLink href="/docs/run-node" active={pathname === '/docs/run-node'}>
          Run your own node
        </NavLink>
      </div>
      <div className="flex flex-col py-4">
        {activeChainId !== garnetHolesky.id && (
          <details>
            <summary
              className={cn('px-4 py-3 font-mono text-lg uppercase text-white/80 md:text-sm')}
            >
              Retrospectives
            </summary>
            <NavLink
              href="/docs/retro-2024-08-01"
              active={pathname === '/docs/retro-2024-08-01'}
              className="px-8 py-2 text-xs"
            >
              2024-08-01 Bridged USDC withdrawal bug
            </NavLink>
          </details>
        )}
        <NavLink
          href="/privacy-policy"
          active={pathname === '/privacy-policy'}
          className="py-2 text-xs"
        >
          Privacy Policy
        </NavLink>
        <NavLink
          href="/terms-of-service"
          active={pathname === '/terms-of-service'}
          className="py-2 text-xs"
        >
          Terms of Service
        </NavLink>
      </div>
    </div>
  );
}

export function MobileNavSidebar() {
  return (
    <Popover className="relative">
      {({ close }) => (
        <>
          <Popover.Button className="px-4">
            <HamburgerIcon />
          </Popover.Button>

          <Popover.Panel className="fixed left-0 top-0 z-10 h-full w-full overflow-y-auto bg-black">
            <button type="button" onClick={close} className="absolute right-[15px] top-[15px]">
              <CloseIcon className="h-[30px] w-[30px]" />
            </button>
            <NavSidebar />
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
}
